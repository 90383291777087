import { createAuth0 } from "@auth0/auth0-vue";

function getAuth0Params() {
  const params = new URLSearchParams(window.location.search);
  return {
    signup: params.get("signup"),
    signup_email: params.get("signup_email"),
  };
}

// Extract the query parameters from the URL
const auth0Params = getAuth0Params();

export const authorizationParams = {
  redirect_uri: import.meta.env.VITE_AUTH_CALLBACK,
  responseType: "token id_token",
  scope: "openid email profile",
  signup: auth0Params.signup || "",
  signup_email: auth0Params.signup_email || "",
  audience: import.meta.env.VITE_AUTH_AUDIENCE,
};

const auth0 = createAuth0({
  domain: import.meta.env.VITE_AUTH_DOMAIN,
  clientId: import.meta.env.VITE_AUTH_CLIENTID,
  authorizationParams,
});

export default auth0;
