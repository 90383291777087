import Paranoid from "./Paranoid";
class Organization extends Paranoid {
  name: string = "";
  logoUrl: string = "";
  notificationTemplate: string = "";
  subscriptionId: string = "";
  notificationTemplateAsync: string = "";
  autoStartRecordings: boolean = false;
  useAdvanceDeviceSelection: boolean = false;
  storageBucket: string = "";
  isSetup: boolean = false;
  baseBucket: string = "";
  recordingsBucket: string = "";
  snapshotsBucket: string = "";
  inboundCallModel: string = "";
  apiKey: string = "";
  apiSecret: string = "";
  trialDurationDays: number = 0;
  trialStartDate: Date = new Date();
  isTrial: boolean = false;
  isActiveTrial: boolean = false;
  isEndedTrial: boolean = false;
  isTrialCCNow: boolean = false;
  statusOverride: string = "";
  isSubscriptionActive: boolean = false;
  trialEndDate: Date = new Date();
  trialDaysRemaining: number = 0;
  inboundCallUnassignedDuration: number = 45;
  inboundCallAssignedDuration: number = 15;
  requireAnswerValidationEmail: boolean | null = false;
  requireAnswerValidationSMS?: boolean | null = false;
  requireAnswerValidationDashboard?: boolean | null = false;
  defaultBackCamera?: boolean | null = false;
  hostCameraDisabled?: boolean | null = false;
  canReadUnassignedConvos: boolean = false;
  virtualBackgroundImageUrl?: string | null = "";
  autoApplyVirtualBackgroundOnStart: boolean | null = false;
}
export default Organization;
