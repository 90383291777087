import { io, Socket } from "socket.io-client";
import Conversation from "@/types/Conversation";
import * as Sentry from "@sentry/vue";
import User from "@/types/User";
import Account from "@/types/Account";

interface ServerToClientEvents {
  account_update: (data: { account: Account; user: User }) => void;
  conversation_update: (data: { conversation: Conversation }) => void;
}

interface ClientToServerEvents {}

export interface AccountUpdateDetail {
  account: Account;
  user: User;
}

export interface SocketConversationUpdate {
  conversation: Conversation;
}

const socket: Socket<ServerToClientEvents, ClientToServerEvents> = io(
  `${import.meta.env.VITE_SOCKET_ROOT ?? "jake-socket.ngrok.app"}/admin`,
  {
    transports: ["websocket"],
    autoConnect: false,
    reconnection: true,
    reconnectionAttempts: Infinity,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
  }
);

socket.on("connect_error", (error) => {
  if (socket.active) {
    // temporary failure, the socket will automatically try to reconnect
  } else {
    socket.connect();
    Sentry.captureException(error);
  }
});

export default socket;