<template>
  <v-text-field
    v-bind="$attrs"
    :v-model="value"
    :label="props.label"
    :rules="[(v : string) => validator.validate('Default', v)]"
    @input="(v : any) => $emit('input', v)"
  >
    <template v-slot:label>
      <span>{{ label }}</span
      ><span class="required ml-1" v-if="isRequired">*</span>
    </template>
  </v-text-field>
</template>
<script setup lang="ts">
import { Ref, ref, useAttrs } from "vue";
import {
  VuetifyValidation,
  ValidationRule,
} from "@/components/extensions/Validation";
const attrs = useAttrs() as {
  max: string | undefined;
  min: string | undefined;
  readonly: string | undefined;
  type: string | undefined;
  minlen: string | undefined;
};
// Props should only contain values not passed through to the input. Don't include things like max, min, readonly etc. (use attr for passthrough values).
const props = defineProps([
  "label",
  "value",
  "len",
  "rules",
  "required",
  "fieldName",
  "fieldA",
]);
const emits = defineEmits(["input"]);
const isRequired: Ref<boolean> = ref(false);
defineOptions({
  inheritAttrs: true,
  customOptions: {
    max: 12,
  },
});
const rules: Array<ValidationRule> = props.rules || [];
let len = props.len || 250;
let readonly =
  attrs.readonly !== undefined && attrs.readonly !== null && attrs.readonly;
if (props.len <= 0 || readonly) {
  len = undefined;
}
let max = undefined;
if (attrs?.max && !readonly && attrs?.type && attrs?.type === "number") {
  try {
    max = parseInt(attrs.max);
    if (max) {
      rules.push(VuetifyValidation.MaxValueValidation(max));
    }
  } catch (e) {
    console.warn(`Max value failed. ${attrs.max}`, e);
  }
}
let min = undefined;
if (attrs?.min && !readonly && attrs?.type && attrs?.type === "number") {
  try {
    min = parseInt(attrs.min);
    if (min) {
      rules.push(VuetifyValidation.MinValueValidation(min));
    }
  } catch (e) {
    console.warn(`Min value failed. ${attrs.min}`, e);
  }
}
if (
  props.hasOwnProperty("required") &&
  props.required != undefined &&
  props.required !== false &&
  props.required !== "false"
) {
  isRequired.value = true;
  rules.push(
    VuetifyValidation.RequiredValidation(
      props.fieldName ?? "value",
      props.fieldA ?? "a"
    )
  );
}
if (attrs?.type && attrs?.type === "email") {
  rules.push(VuetifyValidation.EmailValidation);
}
if (attrs?.type && attrs?.type === "tel") {
  rules.push(VuetifyValidation.PhoneNumberValidation);
}
if (len) {
  try {
    const maxLen = parseInt(len);
    if (maxLen) {
      rules.push(VuetifyValidation.MaxLengthValidation(maxLen));
    }
  } catch (e) {
    console.warn(`Max length value failed. ${len}`, e);
  }
}
if (attrs?.minlen) {
  try {
    const minLen = parseInt(attrs.minlen);
    if (minLen) {
      rules.push(VuetifyValidation.MinLengthValidation(minLen));
    }
  } catch (e) {
    console.warn(`Min length value failed. ${attrs.minlen}`, e);
  }
}
const validator = new VuetifyValidation({
  Default: {
    rules: rules,
  },
});
</script>
<style scoped></style>
