import { reactive } from "vue";

interface Toast {
  message: string;
  timeout: number;
  type: "success" | "info" | "warning" | "error" | undefined;
  closable: boolean;
  action?: ToastAction;
}

interface ToastAction {
  message: string;
  skipActionClose: boolean;
  fn: () => Promise<void>;
}

class Toastable {
  public static displayedToasts: Array<Toast> = reactive([]);

  private static removeToast(toast: Toast) {
    const index = this.displayedToasts.indexOf(toast); // accessing the value property
    if (index !== -1) {
      this.displayedToasts.splice(index, 1); // accessing the value property
    }
  }
  private static show(
    message: string,
    type: "success" | "info" | "warning" | "error" | undefined,
    timeout: number,
    closable: boolean,
    action?: ToastAction
  ): Toast {
    const toast: Toast = {
      message,
      type,
      timeout,
      closable,
      action,
    };
    this.displayedToasts.unshift(toast);
    if (timeout > 0) {
      setTimeout(() => {
        this.removeToast(toast);
      }, toast.timeout);
    }
    return toast;
  }
  public static hide(toast: Toast) {
    this.removeToast(toast);
  }
  // Exposed in case you need to change closable and not the timeout.
  public static DEFAULT_TIMEOUT_ERROR = 5000;
  public static DEFAULT_TIMEOUT_WARN = 4000;
  public static DEFAULT_TIMEOUT_INFO = 3500;
  public static DEFAULT_TIMEOUT_SUCCESS = 2500;
  // Please use default timeouts for consistency.
  static error = (
    message: string,
    timeout: number = this.DEFAULT_TIMEOUT_ERROR,
    closable: boolean = true,
    action?: ToastAction
  ) => this.show(message, "error", timeout, closable, action);
  static warn = (
    message: string,
    timeout: number = this.DEFAULT_TIMEOUT_WARN,
    closable: boolean = true,
    action?: ToastAction
  ) => this.show(message, "warning", timeout, closable, action);
  static info = (
    message: string,
    timeout: number = this.DEFAULT_TIMEOUT_INFO,
    closable: boolean = true,
    action?: ToastAction
  ) => this.show(message, "info", timeout, closable, action);
  static success = (
    message: string,
    timeout = this.DEFAULT_TIMEOUT_SUCCESS,
    closable: boolean = true,
    action?: ToastAction
  ) => this.show(message, "success", timeout, closable, action);
}

export { Toastable };
export type { Toast };
