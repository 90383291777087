import { defineStore } from "pinia";
import { Ref, ref } from "vue";
import auth0 from "@/plugins/auth0";
import * as Sentry from "@sentry/browser";
import ResponseWithData from "@/types/ResponseWithData";
import { Toastable } from "@/components/extensions/Toastable";

export const useApiStore = defineStore("apiStore", () => {
  const organizationId: Ref<string> = ref("");
  const errorMessages: Ref<Array<string>> = ref([]);
  const baseURL: string = import.meta.env.VITE_API_ROOT;

  const buildURL = (extra: string): string => {
    return baseURL + extra;
  };

  const retryRequest = async (fn: Function, retries = 1): Promise<any> => {
    let attempt = 0;
    while (attempt < retries) {
      try {
        return await fn();
      } catch (error) {
        if (
          !(error instanceof TypeError) ||
          !error.message.includes("Failed to fetch")
        ) {
          throw error;
        }
        attempt++;
        if (attempt >= retries) {
          throw error;
        }
      }
    }
  };

  const authCall = async (
    url: string,
    method: string,
    params: any,
    data: any
  ): Promise<ResponseWithData> => {
    const makeRequest = async () => {
      const token = await auth0.getAccessTokenSilently({ cacheMode: "on" });
      const headers: any = {
        Accept: "application/json, text/plain, */*",
        "Accept-Encoding": "gzip, deflate, br",
        Authorization: `Bearer ${token}`,
      };
      if (organizationId.value) {
        headers["x-organization-id"] = `${organizationId.value}`;
      }
      if (method === "get") {
        // GET METHODS ARE NOT ALLOWED BODIES.
        data = null;
      }
      if (data && !(data instanceof FormData)) {
        headers["Content-Type"] = "application/json";
        if (data instanceof Array || data instanceof Object) {
          data = JSON.stringify(data);
        }
      }
      let constructedUrl = url;
      if (params) {
        const paramsArray: Array<any> = [];
        Object.keys(params).forEach((e) => {
          let param = params[e];
          if (param instanceof Array || param instanceof Object) {
            param = JSON.stringify(param);
          }
          paramsArray.push([e, param]);
        });
        constructedUrl = `${url}?${new URLSearchParams(paramsArray)}`;
      }
      const response = await fetch(constructedUrl, {
        method: method,
        mode: "cors",
        cache: "no-cache",
        headers: headers,
        redirect: "follow",
        referrerPolicy: "strict-origin-when-cross-origin",
        body: data,
      });
      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          await auth0.loginWithRedirect({
            appState: {
              target: "/",
            },
          });
          return response as ResponseWithData;
        }
        const contenttype = response?.headers?.get("Content-Type");

        if (contenttype && contenttype.indexOf("application/json") > -1) {
          const json = await response.json();
          if (json.error) {
            throw new Error(json.error);
          }
          if (json.message) {
            throw new Error(json.message);
          }
        } else if (
          contenttype &&
          (contenttype.indexOf("application/text") > -1 ||
            contenttype.indexOf("text/plain"))
        ) {
          const text = await response.text();
          throw new Error(text);
        }
        throw new Error("Error connecting to API server.");
      }
      (response as ResponseWithData).data = await response.json();
      return response as ResponseWithData;
    };

    try {
      return await retryRequest(makeRequest, 2); // Retry once before throwing an error
    } catch (e: any) {
      try {
        Sentry.captureException(e, {
          tags: {
            file: "api.ts",
          },
          extra: {
            data: data,
            params: params,
            url: url,
            method: method,
          },
        });
      } catch (e) {}
      errorMessages.value = [];
      if (e && e.message) {
        if (e.message.includes("User not found")) {
          await auth0.logout();
          window.location.reload();
        }
      }
      if (e.response && e.response.data) {
        if (e.response.data.message) {
          errorMessages.value.push(e.response.data.message);
        } else if (e.response.data.messages) {
          errorMessages.value = e.response.data.messages;
        }
      } else {
        errorMessages.value.push(`${e.message}`);
      }
      if (
        errorMessages.value &&
        Array.isArray(errorMessages.value) &&
        errorMessages.value.length > 0
      ) {
        throw errorMessages.value[0];
      }
      throw e;
    }
  };

  const handleAndDisplayMessage = (e: any) => {
    if (typeof e === "string") {
      Toastable.error(e);
    } else if (e && e.message) {
      Toastable.error(e.message);
    }
  };

  const getAuth = async (
    url: string,
    params: {} = {}
  ): Promise<ResponseWithData> => {
    try {
      return await authCall(buildURL(url), "get", params, {});
    } catch (e: any) {
      handleAndDisplayMessage(e);
      throw e;
    }
  };

  const postAuth = async (
    url: string,
    data: object,
    params: {} = {}
  ): Promise<ResponseWithData> => { 
    try {
      return await authCall(buildURL(url), "post", params, data);
    } catch (e: any) {
      handleAndDisplayMessage(e);
      throw e;
    }
  };

  const putAuth = async (url: string, data: {}): Promise<ResponseWithData> => {
    try {
      return await authCall(buildURL(url), "put", {}, data);
    } catch (e: any) {
      handleAndDisplayMessage(e);
      throw e;
    }
  };

  const deleteAuth = async (
    url: string,
    data: {}
  ): Promise<ResponseWithData> => {
    try {
      return await authCall(buildURL(url), "delete", {}, {});
    } catch (e: any) {
      handleAndDisplayMessage(e);
      throw e;
    }
  };

  return {
    organizationId,
    getAuth,
    postAuth,
    putAuth,
    deleteAuth,
    errorMessages,
  };
});
