<template>
    <v-text-field
    label="Phone Number"
    type="tel"
    center-affix
    :rules="[(v : string) => validator.validate('Default', v)]"
    v-model="phoneNumber"
    @update:modelValue="formatActivePhoneNumber"
  >
    <template v-slot:prepend-inner>
        <div @mousedown.stop @click.stop style="display: flex; align-items: center;">
            <v-select
                v-model="countryCode"
                density="compact"
                item-title="name"
                item-value="code"
                variant="plain"
                :items="countries"
                style="width: 64px;"
                center-affix
                item-props
                hide-details
                hide-no-data
                hide-spin-buttons 
                :menu-props="{
                    openDelay: 0,
                    width: '300px'
                }"
                :list-props="{
                    class: 'fix-spacing-list pa-2'
                }"
                class="fix-input-spacing"
                @update:modelValue="replaceCountryCode"
                >
                    <template v-slot:selection="{ item }">
                        <v-icon size="x-large" v-html="CountryFlagSvg[item.raw.code]"></v-icon>
                    </template>
                    <template v-slot:item="{props, item, index}">
                        <v-list-item v-bind="props" :title="item.title" :subtitle="item.raw.dialCode" :key="item.raw.code" class="rounded pa-2 text-truncate" lines="one">
                            <template v-slot:prepend>
                                <v-icon size="x-large" v-html="CountryFlagSvg[item.raw.code]"></v-icon>
                            </template>
                        </v-list-item>
                    </template>
            </v-select>
        </div>
    </template>
  </v-text-field>
</template>
<script lang="ts" setup>
import { Ref, ref, onBeforeMount, onBeforeUpdate } from "vue";
import { parsePhoneNumber, CountryCode, AsYouType } from "libphonenumber-js";
import { VuetifyValidation } from "@/components/extensions/Validation";
import CountryList from 'country-list-with-dial-code-and-flag'
import CountryFlagSvg from 'country-list-with-dial-code-and-flag/dist/flag-svg'

const countries = CountryList.getAll({ withSecondary: false });

const phoneNumber = defineModel<string>({ required: true });
const props = defineProps(["rules"]);

const countryCode: Ref<string> = ref('');
const previousCountryCode: Ref<string> = ref('');

/* Attempts to find the current country of the user. */
const getCountryFromService = async () : Promise<string> => {
    try {
        return await fetch("https://ip2c.org/s")
            .then((response) => response.text())
            .then((response) => {
                const result = (response || "").toString();
                if (!result || result[0] !== "1") {
                    return "US";
                }

                return result.substring(2, 4);
            });
    } catch (e) { 
        return 'US';
    }
};

const getCountryFromPhoneNumber = async (phoneNumberInput: string): Promise<string> => {
    const code = countryCode.value?.toUpperCase() as CountryCode ?? "US";
    try {
        const phoneNumberParsed = parsePhoneNumber(phoneNumberInput, {
            defaultCountry: code
        });
        if (phoneNumberParsed && phoneNumberParsed.country) {
            return phoneNumberParsed.country;
        }
        return (await getCountryFromService()) || 'US';
    } catch (_) {
        return (await getCountryFromService()) || 'US';
    }
}

const formatActivePhoneNumber = async (phoneNumberInput: string) => {
    // If no input was given, no need to do anything. 
    if (!phoneNumberInput) {
        return;
    }
    // We always want an interational number, so lets fix it if its not.
    if (phoneNumberInput.indexOf('+') === -1) {
        const dialCode = getDialCodeForCountry(countryCode.value as CountryCode ?? "US");
        phoneNumberInput = `${dialCode} ${phoneNumberInput}`
    }
    // This will allow us to format partial numbers, but won't error out on partials. { defaultCountry: countryCode.value as CountryCode }
    try {
        const parser = new AsYouType();
        const phoneFormatted = parser.input(phoneNumberInput);
        phoneNumber.value = phoneFormatted;
        // We want to check the country code of the number, if it's wrong, update it.
        if (parser.country && parser.country != countryCode.value) {
            countryCode.value = parser.country;
            previousCountryCode.value = parser.country;
        }
    } catch (_) { }
}

const getDialCodeForCountry = (c: CountryCode) => {
    const country = CountryList.findOneByCountryCode(c);
    return country?.dialCode ?? "1";
}

/* Function replaces the country code value that existed before, with a new one from the select component. */
const replaceCountryCode = (countryCode: string) => {

    const previousDialCode = previousCountryCode.value ? getDialCodeForCountry(previousCountryCode.value as CountryCode ?? "US") : "";
    const dialCode = getDialCodeForCountry(countryCode as CountryCode ?? "US");
    if (previousCountryCode && phoneNumber.value.indexOf('+') > -1) {
        phoneNumber.value = phoneNumber.value.replace(`${previousDialCode}`, `${dialCode}`);
    } else {
        phoneNumber.value = phoneNumber.value = `${dialCode} ${phoneNumber.value}`;
    }
    previousCountryCode.value = countryCode;
}

/* Set the initial value based on the users geographical location. */
onBeforeMount(async () => {
    countryCode.value = (await getCountryFromService()) || 'US';
    previousCountryCode.value = countryCode.value as CountryCode;
})

const validator = new VuetifyValidation({
  Default: {
    rules: [
      VuetifyValidation.MaxLengthValidation(18, "phone"),
      VuetifyValidation.PhoneNumberValidation,
        ].concat(props.rules || [])
  },
});

/* 
    VUE BUG: PhoneNumber isn't available until after the mounted event fires because of the two way binding and the proxy. 
    This basically waits for it to be populated (or for the user to type the first value), then tries to set the country code.
*/
let skipUpdate = false;
onBeforeUpdate(async () => { 
    if (!skipUpdate && phoneNumber.value) {
        skipUpdate = true;
        countryCode.value = await getCountryFromPhoneNumber(phoneNumber.value);
        previousCountryCode.value = countryCode.value as CountryCode;
    }
 })
</script>
<style scoped lang="scss">
    .fix-input-spacing:deep(.v-field__input) {
        padding-top: 0;
    }
</style>
<style lang="scss">
    .fix-spacing-list .v-list-item__content {
        margin-left: 8px;
    }
</style>