import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";

interface ValidationRule {
  isValid: (v: any) => boolean | string;
}
export type { ValidationRule };

interface ValidationConfig {
  [key: string]: {
    rules: Array<ValidationRule>;
  };
}

const isString = (v: string | boolean) => {
  return typeof v === "string";
};

class VuetifyValidation<T extends ValidationConfig> {
  private config: T;
  constructor(config: T) {
    this.config = config;
  }
  validate<T extends ValidationConfig>(
    field: Extract<keyof T, string>,
    value: any
  ): boolean | string {
    const rules = this.config[field].rules;
    for (var i = 0, l = rules.length; i < l; i++) {
      const result = rules[i].isValid(value);
      if (isString(result)) return result;
    }
    return true;
  }

  static EmailValidation: ValidationRule =
    VuetifyValidation.CustomRegexValidation(
      new RegExp(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ),
      "Please enter a valid email."
    );

  static RequiredValidation(
    fieldName: string = "value",
    a: string = "a"
  ): ValidationRule {
    return {
      isValid: (v: any) => !!v || `Please enter ${a} ${fieldName}.`,
    };
  }

  static MaxLengthValidation(
    param: number,
    fieldName?: string
  ): ValidationRule {
    return {
      isValid: (v: string) =>
        !v ||
        v.length <= param ||
        `You have exceeded the maximum amount of characters allowed in this field.`,
    };
  }

  static MinLengthValidation(
    param: number,
    fieldName?: string
  ): ValidationRule {
    return {
      isValid: (v: string) =>
        !v ||
        v.length > param ||
        `${fieldName ?? "Field"} has a minimum length of ${param}.`,
    };
  }

  static MaxValueValidation(param: number): ValidationRule {
    return {
      isValid: (v: number) =>
        !v ||
        v <= param ||
        `Please enter a number less then or equal to ${param}.`,
    };
  }

  static MinValueValidation(param: number): ValidationRule {
    return {
      isValid: (v: number) =>
        !v ||
        v >= param ||
        `Please enter a number greater than or equal to ${param}.`,
    };
  }

  static CustomRegexValidation(regex: RegExp, message: string): ValidationRule {
    return {
      isValid: (v: string) => {
        return !v || regex.test(v) || message;
      },
    };
  }

  static isNumberValidation: ValidationRule = {
    isValid: (v: any) => !isNaN(Number(v)) || "Please enter a valid number.",
  };

  static CustomFunctionValidation(
    func: (v: any) => boolean | string
  ): ValidationRule {
    return {
      isValid: func,
    };
  }

  static PhoneNumberValidation: ValidationRule = {
    isValid: (v: string) =>
      !v || isValidPhoneNumber(v) || "Please enter a valid phone number.",
  };

  static NameCharacterRule: ValidationRule = {
    isValid(value: string): boolean | string {
      if (typeof value !== "string") {
        return "Invalid input type. Must be a string.";
      }
      const regex = /^[a-zA-Z.'\- ]*$/;
      if (!regex.test(value)) {
        return "Please enter your name using alphabets (a-z, A-Z), periods (.), apostrophes ('), hyphens/dashes (-), and spaces.";
      }
      return true;
    },
  };
}
export { VuetifyValidation };
