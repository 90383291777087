<template>
  <v-app>
    <router-view name="appbar" />
    <router-view name="navigation" />
    <v-main class="overflow-y-auto d-flex">
      <router-view />
    </v-main>
    <div id="toastContainer" class="top-toast-wrapper">
      <v-alert
        v-for="toast in Toastable.displayedToasts"
        :type="toast.type"
        density="compact"
        prominent
        :closable="toast.closable"
      >
        <div v-html="toast.message"></div>
        <template v-slot:append v-if="toast.action">
          <v-btn
            variant="tonal"
            size="small"
            slim
            @click="
							(e : MouseEvent) => {
                if (!toast.action?.skipActionClose) {
                  (e.target as any).closest('div.v-alert').remove();
                }
                if (toast && toast.action)
                {
                  toast.action.fn();
                }
              }"
          >
            {{ toast.action.message }}
          </v-btn>
        </template>
      </v-alert>
    </div>
  </v-app>
</template>
<script setup lang="ts">
import "./styles/styles.css";
import Socket from "./socket";
import { Toastable, Toast } from "@/components/extensions/Toastable";
import "./assets/fonts/liveswitch/liveswitch.css";
let toast: Toast | null = null;
Socket.on('connect', () => {
  if (toast) {
    Toastable.hide(toast);
    Toastable.success("You have been reconnected to the server.");
    toast = null;
  }
});
Socket.on('disconnect', () => {
  if (toast) {
    Toastable.hide(toast);
  }
  toast = Toastable.error(
    "You have been disconnected from the server. Please refresh the page to use LiveSwitch Concierge.",
    0,
    false
  );
})
</script>
<style>
html,
body,
div#app {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

main {
  background-color: #f9fafc;
}

input:read-only {
  cursor: default;
}

.v-input .v-field--variant-solo,
.v-select .v-field--variant-solo {
  box-shadow: none !important;
  border: 1px solid rgb(var(--v-theme-border-color));
}

.v-input .v-field--variant-solo .required,
.v-select .v-field--variant-solo .required {
  color: rgb(var(--v-theme-blue));
}

.v-field--focused.v-field--variant-solo {
  border-color: rgb(var(--v-theme-blue));
}

.v-input--error .v-field--error.v-field--variant-solo {
  border-color: rgb(var(--v-theme-red));
  background-color: rgb(var(--v-theme-input-error-bg));
}

.v-card .v-card-text h1 {
  line-height: 2.5em !important;
}

div.v-switch > .v-input__details {
  display: none;
}

.required {
  color: rgb(var(--v-theme-blue));
}

a {
  text-decoration: none;
}

.v-card-actions {
  gap: 8px;
}

.v-table.v-data-table {
  border-radius: 20px;
}

.v-btn.v-btn--variant-outlined > .v-btn__overlay {
  background-color: #fff;
}

.text-emphasis {
  color: rgb(var(--v-theme-blue));
  font-weight: bold;
}

.text-overline-small {
  /* Overline - Small */
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 9px;
  line-height: 13px;
  /* identical to box height, or 144% */
  letter-spacing: 0.02em;
  text-transform: uppercase;
  /* Manatee Gray/Manatee Gray */
  color: #9b9ea5;
}

.navigation-bar .v-list-item--nav .v-list-item-title {
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.25rem;
}

.v-text-field input:read-only,
.v-text-field textarea:read-only {
  color: rgb(var(--v-theme-grey-darken-1));
}

.v-text-field input:focus:read-only,
.v-text-field input:active:read-only,
.v-text-field textarea:focus:read-only,
.v-text-field textarea:active:read-only {
  color: rgb(var(--v-theme-grey-darken-1));
}

.v-text-field.v-input--readonly div.v-counter,
.v-text-field.v-input--readonly textarea:read-only div.v-counter {
  display: none;
}

.v-field--focused.v-field--variant-solo:has(input:read-only),
.v-field--focused.v-field--variant-solo:has(textarea:read-only) {
  border-color: rgb(var(--v-theme-grey-lighten-2));
  color: rgba(var(--v-theme-on-surface), var(--v-high-emphasis-opacity));
}

div.v-input--readonly
  > div.v-input__control
  > div.v-field
  > div.v-field__field
  > label.v-label {
  color: rgba(var(--v-theme-on-surface), var(--v-high-emphasis-opacity));
  opacity: 0.6;
}

div.v-input--readonly.v-textarea .v-field .v-label.v-field-label--floating {
  visibility: visible !important;
  display: block;
}
div.v-input--readonly.v-textarea .v-field label.v-label {
  display: none;
}

.v-input
  .v-field--variant-solo:has(input:read-only):not(:has(.v-select__selection)),
.v-select
  .v-field--variant-solo:has(input:read-only):not(:has(.v-select__selection)),
.v-input
  .v-field--variant-solo:has(textarea:read-only):not(
    :has(.v-select__selection)
  ),
.v-select
  .v-field--variant-solo:has(textarea:read-only):not(
    :has(.v-select__selection)
  ) {
  border-color: rgb(var(--v-theme-grey-lighten-2));
}

.v-input.v-input--readonly label > span.required,
.v-input.v-input--disabled label > span.required {
  display: none;
}

.v-bottom-navigation .v-btn__content {
  font-size: 0.688rem;
  color: rgb(var(--v-theme-bottom-nav-color));
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 0.915rem;
}

.v-bottom-navigation .v-btn--selected .v-btn__content {
  color: rgb(var(--v-theme-bottom-nav-selected-color));
}

.v-icon[class^="icon-"],
.v-icon[class*=" icon-"] {
  font-family: "liveswitch" !important;
}

.stop-safari-title::after {
  content: "";
  display: block;
}

.text-overflow-hidden {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.text-nowrap {
  text-wrap: nowrap;
  white-space: nowrap;
}

div.mobile-display .overflow-column-mobile {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: normal;
  white-space: nowrap;
}

.top-toast-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 12px;
  padding: 12px;
  z-index: 99999;
  align-items: center;
  pointer-events: none;
}
.top-toast-wrapper > .v-alert {
  grid-template-areas:
    "prepend content append close"
    "prepend content append close";
  pointer-events: auto;
}
.top-toast-wrapper > .v-alert .v-alert__close {
  pointer-events: auto;
}

.top-toast-wrapper > .v-alert .v-alert__close > button {
  align-self: center;
}

.top-toast-wrapper > .v-alert > .v-alert__append,
.top-toast-wrapper > .v-alert > .v-alert__close {
  align-self: center !important;
}

.two-col-display-table {
  width: 100%; /* Ensures the table takes full width of its container */
  table-layout: fixed; /* Enables fixed table layout to respect width settings */
}
div#app .two-col-display-table tr td {
  border-bottom-width: 0 !important;
  word-wrap: break-word;
  white-space: normal;
  padding: 8px 0;
}
div#app .two-col-display-table tr td:first-child {
  width: 1%; /* Small percentage to make it take only the necessary space */
  font-weight: 600;
  padding-right: 16px;
  max-width: 10rem;
}

.two-col-display-table tr td:nth-child(2) {
  width: auto; /* Takes up the remaining space */
}
.flex-basis-100 {
  flex-basis: 100% !important;
}
.text-break-all {
  white-space: normal;
  word-break: break-all;
  overflow-wrap: anywhere;
}
.text-truncate {
  text-wrap: nowrap;
}

div#app div.v-application .text-help-icon {
  margin-left: 4px;
}

div#app div.v-application .icon-circle-help-stroke {
  color: rgb(var(--v-theme-grey-darken-1));
  font-size: 21px;
  vertical-align: middle;
  line-height: 1;
}
div#app div.v-application .icon-circle-help-stroke:hover {
   color: rgb(var(--v-theme-grey-darken-3));
}
.v-table th {
  background-color: rgb(var(--v-theme-white));
}

div#app .v-btn--size-default:has(span.v-btn__prepend) {
  padding-left: 16px;
}

div#app .v-btn--size-small:has(span.v-btn__prepend) {
  padding-left: 14px;
}

.v-btn--size-small span.v-btn__prepend {
  margin-right: 6px!important;
}

.font-size-2 {
  font-size: 2rem !important;
}

.back-icon::before {
  margin: 0 !important;
}

.back-icon:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.v-list-item {
  grid-template-columns: 37px 1fr auto !important;
}

.v-list-item.content-only {
  grid-template-areas: "content";
  grid-template-columns: auto !important;
}

/* 
    The icon-circle-help is exclusively used to show hover tooltips to guide users.
    If they have no way to hover, then these icons are not helpful.
  */
@media (hover: none) {
  .v-icon.icon-circle-help-stroke {
    display: none !important;
  }
  div#app div.v-application .v-btn .v-icon.icon-circle-help-stroke,
  div#app div.v-application div.v-navigation-drawer__content .v-icon.icon-circle-help-stroke {
    display: block !important;
    color: rgb(var(--v-theme-black));
  }
}
</style>
<style lang="scss">
@use "sass:map";
@use "@/styles/settings";
@media #{map.get(settings.$display-breakpoints, 'sm-and-down')} {
  .v-container > div.v-row > div.v-col {
    padding: 12px;
  }
  main.v-main {
    padding-top: calc(var(--v-layout-top) + 12px);
  }
  div#app .text-h3 {
    font-size: 2.4rem !important;
  }
  div#app .text-h4 {  
    font-size: 1.5rem !important;
  }
}
.content-width {
  max-width: fit-content;
}
</style>
