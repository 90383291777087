import Dialog from "@/components/extensions/Dialog.vue";
import GenericTable from "@/components/extensions/Table.vue";
import TextField from "@/components/form/textfield.vue";
import PhoneField from "@/components/form/phoneinput.vue";
// Directives
import { trackDirective } from "@/components/directives/track";
import { testDirective } from "@/components/directives/test";

export function registerGlobalComponents(app: any) {
  app
    .component("Dialog", Dialog)
    .component("GenericTable", GenericTable)
    .component("TextField", TextField)
    .component("PhoneField", PhoneField)
    .directive("test", testDirective)
    .directive("track", trackDirective);
}
