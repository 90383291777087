export interface TEvent<T = any> {
  name: string;
  data: T;
}

export interface LoggerProvider {
  trackEvent(event: TEvent): void;
}

class GoogleAnalyticsProvider implements LoggerProvider {
  trackEvent(event: TEvent): void {
    if ((globalThis as any).dataLayer) {
      (globalThis as any).dataLayer.push({ 'event': `track:${event.name.replace(/_([a-z])/g, (match, group) => group.toUpperCase())}` });
    }
  }
}

class AppcuesProvider implements LoggerProvider {
  trackEvent(event: TEvent): void {
    if ((globalThis as any).Appcues) {
      (globalThis as any).Appcues.track(event.name, event.data);
    }
  }
}

class Logger {
  private providers: LoggerProvider[] = [];

  constructor() {
    this.providers.push(new AppcuesProvider());
    this.providers.push(new GoogleAnalyticsProvider());
  }

  // Add a new provider
  public addProvider(provider: LoggerProvider): void {
    this.providers.push(provider);
  }

  // Log an event to all providers
  public log<T>(event: TEvent<T>): void {
    this.providers.forEach((provider) => provider.trackEvent(event));
  }
}

const Logging = new Logger();
export default Logging;
