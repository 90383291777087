import { Directive, DirectiveBinding } from "vue";
import Logging, { TEvent } from "@/plugins/track";

interface trackingEvent {
  action: string;
  node: string;
  testId: string;
}

export const trackDirective: Directive = {
  mounted(el, binding: DirectiveBinding, vNode: any) {
    if (binding.value) {
      const event: TEvent<trackingEvent> = {
        name: binding.value,
        data: {
          action: "",
          testId: "",
          node: vNode.type,
        },
      };
      // If the item also contains a test id lets save it to the event as well.
      const testId = el.getAttribute("data-test-id");
      if (testId) {
        event.data.testId = testId;
      }
      // Handle the different node types here as needed.
      if (vNode.type === "button") {
        // If the node type is button, we want to track clicks.
        el.addEventListener("click", () => {
          console.log("click");
          event.name = `${binding.value}_clicked`;
          event.data.action = `click`;
          Logging.log(event);
        });
      }
    }
  },
};
export default trackDirective;
