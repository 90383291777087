import { h } from "vue";
import type { IconSet, IconAliases } from "vuetify";
import { VClassIcon } from "vuetify/lib/components/index.mjs";

const aliases: IconAliases = {
  collapse: "icon-chevron-up",
  complete: "icon-check-heavy",
  cancel: "icon-circle-slash",
  close: "icon-close-large",
  delete: "icon-trash-full-stroke",
  clear: "icon-close-circle-stroke",
  calendar: "icon-calendar-check-stroke",
  success: "icon-check-circle",
  info: "icon-info-circle-stroke",
  warning: "icon-warning-triangle",
  error: "icon-warning-star",
  prev: "icon-chevron-left",
  next: "icon-chevron-right",
  sortDesc: "icon-chevron-up",
  sortAsc: "icon-chevron-down",
  checkboxOn: "icon-checkbox-marked-stroke",
  checkboxOff: "icon-checkbox-unmarked-stroke",
  checkboxIndeterminate: "icon-checkbox-marked-solid",
  delimiter: "icon-dots-vertical-more",
  sort: "icon-arrow-down-up",
  expand: "icon-chevron-down",
  menu: "icon-dots-horizontal-more",
  subgroup: "icon-flow-subgroup",
  dropdown: "icon-caret-down",
  radioOn: " icon-radio-filled",
  radioOff: "icon-radio-unfilled",
  edit: "icon-pencil-edit-stroke",
  eyeDropper: "icon-eye-stroke",
  ratingEmpty: "icon-star-rating-empty",
  ratingFull: "icon-star-rating-solid",
  ratingHalf: "icon-star-rating-half",
  loading: "icon-loading-dots-circle",
  first: "icon-chevron-left-end",
  last: "icon-chevron-right-end",
  unfold: "icon-unfold-more",
  file: "icon-file-blank-stroke",
  plus: "icon-plus-circle-stroke",
  minus: "icon-minus-circle-stroke",
};

const ls: IconSet = {
  component: VClassIcon,
  //component: (props: any) => h(VClassIcon, { ...props }),
};
export { aliases, ls };
