import Paranoid from "./Paranoid";
import Account from "./Account";
class User extends Paranoid {
  firstname: string = "";
  lastname: string = "";
  name: string = "";
  email: string = "";
  externalKey: string = "";
  phone: string = "";
  enabled: boolean = false;
  holidays: string = "";
  timezone: string = "";
  notificationSMS: boolean = false;
  notificationEmail: boolean = false;
  notificationTeams: boolean = false;
  notificationSlack: boolean = false;
  notificationDashboard: boolean = true;
  Account: Account | null = null;
  inviteLink: string = "";
  inviteAccepted: string = "";
}
export default User;
